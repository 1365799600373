<template>
  <div class="start">
    <component :is="template"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CwStart',

  components: {
    'cw-application-start': () => import('@fi/pages/steps/ApplicationStart'),
    'cw-car-loan-application-start': () => import('@fi/pages/steps/CarLoanApplicationStart'),
  },

  data: () => ({
    template: '',
  }),

  computed: {
    ...mapState({
      loanType: state => state.application.product.loanType,
    }),
  },

  created() {
    const { loanType } = this;
    this.setTemplate(loanType);
  },

  methods: {
    setTemplate(loanType) {
      const templates = {
        continuousLoan: 'cw-application-start',
        instalmentLoan: 'cw-application-start',
        carInstalmentLoan: 'cw-car-loan-application-start',
      };

      this.template = templates[loanType];
    },
  },
};
</script>
